import React, { Component } from 'react';
import {Link} from "gatsby";
import PropTypes from "prop-types";

class Pagination extends Component {
    constructor(props) {
        super(props);

        this.currentPage = props.currentPage;
        this.numPages = props.numPages;
        this.url = props.url;

        this.isFirst = this.currentPage === 1;
        this.isLast = this.currentPage === this.numPages;
        this.prevPageLink = this.url + (this.currentPage - 1 === 1 ? "" : (this.currentPage - 1).toString());
        this.nextPageLink = this.url + (this.currentPage + 1).toString() + "/";
    }

  render() {
    let previousPageButton = "page-item", nextPageButton = "page-item";

    if (this.isFirst) {
      previousPageButton = "page-item disabled";
      this.prevPageLink = "";
    } if (this.isLast) {
      nextPageButton = "page-item disabled";
      this.nextPageLink = "";
    }
    return (
      <ul className="justify-content-center mt-5 pagination">
        {this.numPages > 1 &&
        <li className={previousPageButton} key={ 'first-pagination' }>
          <Link href={ this.prevPageLink } className="page-link" rel="prev">
            <span>{ this.props.prevPageSymbol }</span>
          </Link>
        </li>
        }
        { this.numPages === 1 ? null : Array.from({ length: this.numPages }, (_, i) => (
          <li className={ this.currentPage === i + 1 ? "page-item active" : "page-item" }  key={ 'page-' + (i + 1) }>
            <Link className="page-link" key={`pagination-number${i + 1}`} to={ this.url + `${i === 0 ? "" : (i + 1) + "/"}` }>
              <span>{i + 1}</span>
            </Link>
          </li>
        )) }
        {this.numPages > 1 &&
        <li className={nextPageButton} key={'last-pagination'}>
          <Link to={this.nextPageLink} className="page-link" rel="next">
            <span>{this.props.nextPageSymbol}</span>
          </Link>
        </li>
        }
      </ul>
    );
  }
}


Pagination.defaultProps = {
  nextPageSymbol: 'Next',
  prevPageSymbol: 'Prev',
};

Pagination.propTypes = {
    currentPage: PropTypes.number.isRequired,
    numPages: PropTypes.number.isRequired,
    url: PropTypes.string.isRequired,
    nextPageSymbol: PropTypes.string,
    prevPageSymbol: PropTypes.string,
};

export default Pagination;
