import React from "react";
import { Pagination as  PaginationGatsby} from "../../gatsby-core/pagination";
import {Link} from "gatsby";

class Pagination extends PaginationGatsby {
    render() {
        return (
            <ul className="justify-content-center pagination">
                { this.numPages === 1 ? null : Array.from({ length: this.numPages }, (_, i) => (
                    <li className={ this.currentPage === i + 1 ? "page-item active" : "page-item" }  key={ 'page-' + (i + 1) }>
                        <Link className="page-link" key={`pagination-number${i + 1}`} to={ this.url + `${i === 0 ? "" : (i + 1) + "/"}` }>
                            <span>{i + 1}</span>
                        </Link>
                    </li>
                )) }
            </ul>
        );
    }
}

export default Pagination
