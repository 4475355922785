import React from 'react';
import { graphql } from "gatsby";
import Container from "react-bootstrap/Container";
import Layout from "../../layout/layout.component";
import SEO from "../../partials/seo/seo.component";
import Row from "react-bootstrap/Row";
import PropTypes from "prop-types";
import Category from "../../partials/category/category.component";
import Pagination from "../../partials/pagination/pagination.component";
import ROUTES from "../../configs/route_final";

const BestList = (props) => {
    const bests = props.data.allWordpressPost.edges,
        { current_page, num_pages, schema } = props.pageContext;

    return (
        <Layout>
            <SEO data={schema}/>
            <Container>
                <Row className="list-row-wrapper">
                    <div className="list-wrapper">
                        <div className="list-heading-wrapper">
                            <h1 className="list-heading">{ROUTES.best.title}</h1>
                            <div className="list-text" dangerouslySetInnerHTML={{__html: ROUTES.best.description}}></div>
                        </div>
                        <div className="">
                            { bests && Array.isArray(bests) && bests.length > 0 && <Category data={bests}/> }
                            <Pagination currentPage={ current_page } numPages={ num_pages } url={ ROUTES.best.path } nextPageSymbol={'>'} prevPageSymbol={'<'}/>
                        </div>
                    </div>
                </Row>
            </Container>
        </Layout>
    );
};

BestList.propTypes = {
    pageContext: PropTypes.shape({
        schema: PropTypes.object.isRequired,
        current_page: PropTypes.number.isRequired,
        num_pages: PropTypes.number.isRequired,
        limit: PropTypes.number.isRequired,
        skip: PropTypes.number.isRequired,
        path_category: PropTypes.string.isRequired,
    }),
    data: PropTypes.shape({
        allWordpressPost: PropTypes.object.isRequired
    }),
};

export default BestList;

export const pageQuery = graphql`
    query ($limit: Int!, $skip: Int!, $filter: wordpress__POSTFilterInput) {
      allWordpressPost(
        filter: $filter,
        sort: {order: DESC, fields: date},
        limit: $limit,
        skip: $skip
      ) {
        edges {
          node {
            title
            featured_media_srcset
            excerpt
            small_date
            path
          }
        }
      }
    }
`;
